import instance from '@/utils/api'
import store from '@/store'
import router from '@/router'

/**
 * 用户登录
 * @param {*} data 
 * @returns 
 */
export function login(data) {
    return instance({
        url: "/user/login",
        method: 'post',
        data: data
    }).then(res => {
        if (res.success) {
            store.commit('SET_TOKEN', res.data);
            // 在登录成功的处理逻辑中进行页面跳转
            router.push('/admin'); // 跳转到名为 'dashboard' 的路由
        }
    }).catch(error => {
        console.log(error);
    })
}

/**
 * 用户登出
 * @param {*} data 
 * @returns 
 */
export function logout() {
    return instance({
        url: "/user/logout",
        method: 'post',
    }).then(res => {
        if (res.success) {
            store.commit('LOGIN_OUT');
            // 在登录成功的处理逻辑中进行页面跳转
            router.push('/'); // 跳转到名为 'dashboard' 的路由
        }
    }).catch(error => {
        console.log(error);
    })
}

export function queryLoginUserInfo() {
    return instance({
        url: "/user/selectLoginUserInfo",
        method: 'get'
    }).then(res => {
        if(res.success){
            store.commit('SET_USER_INFO', res.data);
            return res;
        }
    }).catch(error => {
        console.log(error);
    })
}

export function selectUserInfoOne(user) {
     // 如果 user 为空，将其设置为一个空的 JSON 对象
     if (!user) {
        user = {};
    }
    // 将数据对象序列化为 JSON 字符串
    const jsonData = JSON.stringify(user);
    return instance({
        url: "/user/selectUserInfoOne",
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data:jsonData
    }).then(res => {
        if(res.success){
            store.commit('SET_USER_INFO', res.data);
            return res;
        }
    }).catch(error => {
        console.log(error);
    })
}

